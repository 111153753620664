[data-v-d90f7dc6] textarea {
  min-height: 145px !important;
}
.leftExample[data-v-d90f7dc6] {
  width: 24%;
  display: inline-block;
  height: 80%;
  position: relative;
}
.item_icon[data-v-d90f7dc6] {
  display: inline-block;
  width: 81%;
}
.item_icon i[data-v-d90f7dc6] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-d90f7dc6] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.leftBlueBorder[data-v-d90f7dc6] {
  width: 4px;
  height: 12px;
  background-color: var(--themeColor, #17a2b8);
  display: inline-block;
}
.top_select[data-v-d90f7dc6] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.styleForm .el-form-item[data-v-d90f7dc6] {
  margin-bottom: 8px;
}