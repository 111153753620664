.top_data[data-v-0baefd20] {
  width: 100%;
  background-color: #edf8fa;
  padding: 10px;
  display: flex;
  align-items: center;
}
.top_data .data_count[data-v-0baefd20] {
  color: #17a2b8;
  margin: 0 5px;
}
.top_data > div[data-v-0baefd20] {
  width: 33%;
  border-right: 1px solid #dbddde;
  text-align: center;
}
.top_data div[data-v-0baefd20]:last-child {
  border-right: none;
}
.top_data .data_title[data-v-0baefd20] {
  margin-right: 20px;
  position: relative;
}
.top_data .data_title[data-v-0baefd20]::before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  left: -11px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--themeColor, #17a2b8);
}
.top_select[data-v-0baefd20] {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.top_select .each[data-v-0baefd20] {
  width: 200px;
  padding: 5px 15px;
  text-align: center;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.top_select .each .icon_bg[data-v-0baefd20] {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: #cae7ef;
  text-align: center;
  line-height: 27px;
  display: inline-block;
  margin-right: 7px;
}
.top_select .each .iconfont[data-v-0baefd20] {
  color: #17a2b8;
}
.top_select .active[data-v-0baefd20] {
  background-color: #edf8fa;
  border-color: #a3d9e1;
}
.filter_style[data-v-0baefd20] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.none-borders .div_p[data-v-0baefd20] {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-borders .div_p[data-v-0baefd20]:last-child {
  border-bottom: none;
}
.item_icon[data-v-0baefd20] {
  cursor: pointer;
}
.item_icon i[data-v-0baefd20] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-0baefd20] {
  font-size: 13px;
  color: #333;
  text-align: left;
}