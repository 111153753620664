.top_data[data-v-71669864] {
  width: 100%;
  background-color: #edf8fa;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.top_data .data_count[data-v-71669864] {
  color: #17a2b8;
  margin: 0 5px;
}
.top_data > div[data-v-71669864] {
  width: 33%;
  border-right: 1px solid #dbddde;
  text-align: center;
}
.top_data div[data-v-71669864]:last-child {
  border-right: none;
}
.top_data .data_title[data-v-71669864] {
  margin-right: 20px;
  position: relative;
}
.top_data .data_title[data-v-71669864]::before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  left: -11px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--themeColor, #17a2b8);
}
.top_select[data-v-71669864] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.item_icon[data-v-71669864] {
  cursor: pointer;
}
.item_icon i[data-v-71669864] {
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-71669864] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}