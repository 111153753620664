.content_top[data-v-48b0e836] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #e3e3e3;
}
.tab-container[data-v-48b0e836] {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
}
.tabs[data-v-48b0e836] {
  display: flex;
  border-bottom: 1px solid var(--themeColor, #17a2b8);
}
.tab[data-v-48b0e836] {
  width: 25%;
  text-align: center;
  padding: 8px 0px;
  cursor: pointer;
  position: relative;
}
.tab.active[data-v-48b0e836] {
  color: var(--themeColor, #17a2b8);
}
.tab .triangle[data-v-48b0e836] {
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 9px;
  height: 9px;
  border: none;
  background: #f3fbfe;
  border-top: 1px solid var(--themeColor, #17a2b8);
  border-right: 1px solid var(--themeColor, #17a2b8);
  transform: rotate(-45deg) translateX(2px) translateY(1px);
  z-index: 2;
}
.echart_top[data-v-48b0e836] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 0;
}
.switch_icon[data-v-48b0e836] {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}
.switch_icon span[data-v-48b0e836] {
  font-size: 12px;
  margin-right: 2px;
}
.rotate--90[data-v-48b0e836] {
  transform: rotate(-90deg);
}
.record[data-v-48b0e836] {
  font-size: 14px;
  color: #333;
}
.record[data-v-48b0e836]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 7px;
}
.echart_style[data-v-48b0e836] {
  padding: 5px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftExample[data-v-48b0e836] {
  width: 16%;
  display: inline-block;
  height: 80%;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.time_color[data-v-48b0e836] {
  font-size: 16px;
  color: var(--themeColor, #17a2b8);
}