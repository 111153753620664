.top_data[data-v-03f3886d] {
  width: 100%;
  background-color: #edf8fa;
  padding: 10px;
  display: flex;
  align-items: center;
}
.top_data div[data-v-03f3886d] {
  width: 33%;
  text-align: center;
}
.top_data .data_count[data-v-03f3886d] {
  color: #17a2b8;
  margin: 0 5px;
}
.top_data .data_title[data-v-03f3886d] {
  margin-right: 20px;
  position: relative;
}
.top_data .data_title[data-v-03f3886d]::before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  left: -11px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--themeColor, #17a2b8);
}
.content_select[data-v-03f3886d] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.content_select .left_select[data-v-03f3886d] {
  display: flex;
  align-items: center;
}
.item_icon[data-v-03f3886d] {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: #333;
}
.item_icon i[data-v-03f3886d] {
  font-size: 13px;
  margin-right: 2px;
}
.content[data-v-03f3886d] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content tr[data-v-03f3886d] {
  padding: 0;
  margin: 0;
}
.content td[data-v-03f3886d] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
  text-align: center;
}